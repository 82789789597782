<template>
  <div class="createOrder">
    <div class="mfcName">
      <strong>面粉厂:{{ mfcName }}</strong>
    </div>
    <div class="product">
      商品信息
    </div>
    <div class="goodsList">
      <div class="item" v-for="item in goodsList" :key="item.id">
        <div class="img">
          <img :src="item.goods_pic" />
        </div>
        <div class="info">
          <div class="title">{{ item.goods_name }}</div>
          <div class="price">￥{{ item.goods_price/100}}</div>
          <div class="num">
            <nut-inputnumber v-model="buyGoodsList[item.goods_id]" button-size="30" disabled input-width="50" />
          </div>
        </div>
      </div>
    </div>
    <div class="payFee"> <strong>应付金额:{{payFee/100}}元</strong></div>
    <div class="userInfo">
      <div v-if="addressList.length > 0 ">
        <nut-cell title="地址" :desc="text" is-link @click="showAddressExist"></nut-cell>
        <nut-address
          v-model:visible="showPopupExist" type="exist"
          :exist-address="addressList"
          @close="close"
          :is-show-custom-address="false"
          @selected="selected"
          exist-address-title="配送至"
        ></nut-address>
      </div>
      <div>
        <nut-button type="primary" size="small" @click="addAddress" >添加收货地址</nut-button>
      </div>
    </div>
    <div class="button">
      <nut-button type="primary" @click="createOrder()">下单</nut-button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import * as goodsApi from "../../api/goods";
import * as addressApi from "../../api/address";
import * as orderApi from "../../api/order";
import * as mfcApi from "../../api/mfc";

export default {
  setup(){
    const jsonDecode = (str) => {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == 'object' && obj ){
            return obj;
          }else{
            return false;
          }
        } catch(e) {
          return false;
        }
      } else {
        return false;
      }
    };
    const router = useRouter();
    const paramJson = useRoute().query.param;
    const mfcId = useRoute().query.mfc_id;
    const param = jsonDecode(paramJson);
    const goodsList = ref([]);
    const buyGoodsList = ref({});
    const payFee = ref(0);
    const mfcName = ref("");
    const defaultAddressId = useRoute().query.address_id;
    if(param === false || param.length == 0){
      Toast.text("param参数错误或为空");
      setTimeout(function(){
        router.push("/");
      },3000)
      return false;
    }
    const getMfcName = async () => {
      const res = await mfcApi.mfcInfo();
      // 模拟请求返回的结果
      if(res.code == 0){
        res.data.list.forEach((item) => {
          if(item.mfc_id == mfcId){
            mfcName.value=item.mfc_name;
          }
        });
      }else{
        Toast.text(res.message);
      }
    }
    getMfcName();
    //购物的逻辑
    const getBuyGoods = async () => {
      const goodsIdsArr = [];
      var buyGoodsListObj = {};
      param.map(function(item) {
        goodsIdsArr.push(item.goods_id);
        buyGoodsListObj[item.goods_id] = item.num;
      });
      buyGoodsList.value = buyGoodsListObj;
      const res = await goodsApi.getGoodsListByGoodsId({goods_ids: goodsIdsArr.join(","), mfc_id: mfcId});
      if(res.code == 0 ){
        res.data.list.forEach( (item) => {
          payFee.value += buyGoodsListObj[item.goods_id] * item.goods_price;
        });
        goodsList.value = res.data.list;
      } else {
        Toast.text(res.message);
      }
    };

    const showPopupExist = ref(false);
    const text = ref('请选择其他地址')
    const addressList = ref([]);
    const addressId = ref(0);
    const getUserAddress = async () => {
      const res = await addressApi.list();
      if(res.code == 0){
        let list = [];
        res.data.list.forEach((item,index) => {
          
          if(index == 0){
            addressId.value = item.address_id;
            text.value = item.address_one + "/" + item.address_two;
            item.is_default = 1;
          }
          
          if(item.address_id == defaultAddressId && index != 0){
            addressId.value = item.address_id;
            text.value = item.address_one + "/" + item.address_two;
            item.is_default = 1;
            list[0]["selectedAddress"] = false;
          }

          list.push({
            id: item.address_id,
            addressDetail: item.address_one + "/" + item.address_two,
            cityName: '',
            countyName: '',
            provinceName: '',
            selectedAddress: item.is_default == 1 ? true : false,
            townName: '',
            name: item.real_name,
            phone: item.mobile,
          });
        });
        addressList.value = list;
      } else {
        Toast.text(res.message);
      }
    };

    const showAddressExist = () => {
      showPopupExist.value = true;
    };
    const close = (val) => {
      text.value = val.data.addressDetail;
      addressId.value = val.data.id;
    };
    const selected = () => {
      // console.log(prevExistAdd);
      // console.log(nowExistAdd);
      // console.log(arr);
    };
    const createOrder = async () => {
      // "order_type"//1.小麦兑换 2.货币购买 3.积分兑换 4.小麦预存 5.面粉支取
      // "total_fee",0);//总金额
      // "points_num",0);//积分使用
      // "pay_fee",0);;//支付金额，单位分
      // "wheat_weight",0);//小麦重量,单位：（克）
      // "address_id",0);//用户地址
      // "goods_json",'[]');//商品信息
      if(addressId.value == 0){
        Toast.text("请先添加收货地址");
        return false;
      }
      const res = await orderApi.add({
        order_type: 2,
        total_fee: payFee.value,
        points_num: 0,
        pay_fee: payFee.value,
        wheat_weight: 0,
        address_id: addressId.value,
        goods_json:paramJson,
        mfc_id: mfcId,
      });
      if(res.code ==0){
        Toast.text("下单成功");
        setTimeout(() => {
          router.push("/order");
        },3000)
      } else {
        Toast.text(res.message);
      }
    };
    const addAddress =()=>{
      router.push("/address/add?param="+ paramJson +"&mfc_id="+mfcId);
    };
    getBuyGoods();
    getUserAddress();
    return {
      createOrder,
      addAddress,
      mfcName,
      goodsList,
      buyGoodsList,
      addressList,
      addressId,
      payFee,
      showPopupExist, showAddressExist, text, close, selected
    };
  }
}
</script>

<style scoped>

.goodsList .item {
  margin-top: .5rem;
  background-color: #ffffff;
  height: 8rem;
}
.goodsList .item .img {
  float: left;
  margin-left: 0.3rem;
  widht:30%;
}
.goodsList .item .info {
  float: left;
  margin-left: 0.3rem;
  widht:60%;
}
.goodsList .item .info .title{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  line-height: 1rem;
  font-size: 1rem;
  width: 15rem;
  padding: .5rem;
  text-align: left;
}

.goodsList .item .info .price {
  font-size: 1rem;
  color: #fa2c19;
  line-height: 1rem;
  width: 15rem;
  text-align: left;
  margin-top: 1rem;
}
.goodsList .item .info .num {
  float: right;
  margin-top: 2rem;
}
.goodsList .item .img img {
  width:7rem;
  height:7rem;
}
.button{
  margin: 1rem 1.5rem;
  text-align: right;
}
.mfcName{
  text-align: center;
  font-size: medium;
  margin-top: 0.5rem;
}
.product{
  margin-left: .2rem;
}
.createOrder{
  padding: 0.5rem;
}
.userInfo{
  margin-top: 1rem;
  padding: 1rem 1rem;
  background-color: #ffffff;
}

.userInfo div{
  margin-top:.5rem
}
.payFee{
  text-align: right;
  padding: 1rem;
}
</style>